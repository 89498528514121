<template>
    <div class="o-layout o-layout--large">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <recess-button
                        :title="BUTTON_TEXT.goBack"
                        icon="back"
                        variant="variant4"
                        class="qa-go-back-button"
                        url="/result"
                    />
                    <title-text
                        :title="PROVIDER.ResultsAndContractsPage.ContractPriceAgreementsPage.DetailsTitle"
                        class="qa-price-agreements-overview-title"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <recess-card variant="variant1">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-5">
                                    <recess-search-input
                                        v-model.trim="selectedFilters.searchQuery"
                                        :placeholder="PROVIDER.ResultsAndContractsPage.ContractPriceAgreementsPage.FilterInput.SearchPriceAgreements.Placeholder"
                                        :label-text="PROVIDER.ResultsAndContractsPage.ContractPriceAgreementsPage.FilterInput.SearchPriceAgreements.Label"
                                        class="qa-account-search" 
                                        @input="(newValue) =>setSpecificFilter('searchQuery', newValue)"
                                    /> 
                                </div>
                                <div class="col-7">
                                    <div class="d-flex align-items-top">
                                        <recess-date-picker
                                            v-model="selectedFilters.minStartDate"
                                            :default-value="selectedFilters.minStartDate"
                                            :label-text="PROVIDER.ResultsAndContractsPage.ContractPriceAgreementsPage.FilterInput.MinStartDate.Label"
                                            :error-message="minStartDateError"
                                            class="qa-min-start-date-price-agreement-filter"
                                            @blur="$v.selectedFilters.minStartDate.$touch()"
                                            date-picker-input-custom-width="100%" 
                                            @input="(newValue) => setMinStartDate(newValue)"
                                        /> 

                                        <recess-date-picker
                                            v-model="selectedFilters.maxStartDate"
                                            :default-value="selectedFilters.maxStartDate"
                                            :label-text="PROVIDER.ResultsAndContractsPage.ContractPriceAgreementsPage.FilterInput.MaxStartDate.Label"
                                            :error-message="maxStartDateError"
                                            class="ml-3 qa-max-start-date-price-agreement-filter"
                                            @blur="$v.selectedFilters.maxStartDate.$touch()"
                                            date-picker-input-custom-width="100%" 
                                            @input="(newValue) => setMaxStartDate(newValue)"
                                        /> 

                                        <div class="d-flex s-simulate-input-holder align-items-center mx-3">-</div>

                                        <recess-date-picker
                                            v-model="selectedFilters.minEndDate"
                                            :default-value="selectedFilters.minEndDate"
                                            :label-text="PROVIDER.ResultsAndContractsPage.ContractPriceAgreementsPage.FilterInput.MinEndDate.Label"
                                            :error-message="minEndDateError"
                                            class="qa-min-end-date-price-agreement-filter"
                                            @blur="$v.selectedFilters.minEndDate.$touch()"
                                            date-picker-input-custom-width="100%" 
                                            @input="(newValue) => setMinEndDate(newValue)"
                                        /> 

                                        <recess-date-picker
                                            v-model="selectedFilters.maxEndDate"
                                            :default-value="selectedFilters.maxEndDate"
                                            :label-text="PROVIDER.ResultsAndContractsPage.ContractPriceAgreementsPage.FilterInput.MaxEndDate.Label"
                                            :error-message="maxEndDateError"
                                            class="ml-3 qa-max-end-date-price-agreement-filter"
                                            @blur="$v.selectedFilters.maxEndDate.$touch()"
                                            date-picker-input-custom-width="100%" 
                                            @input="(newValue) => setMaxEndDate(newValue)"
                                        /> 
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12 d-flex align-items-end justify-content-end"> 
                                    <recess-button 
                                        variant="tertiary"
                                        :title="PROVIDER.ResultsAndContractsPage.OrderFeesPage.ResetFilters"
                                        class="ml-4 qa-reset-filters"
                                        @click.native.prevent="resetFilters()"
                                    />
                                </div>
                            </div>
                        </div>
                    </recess-card> 

                    <price-agreement-list :set-selected-filters="localFilters" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {BUTTON_TEXT} from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json' 
import { validationMixin } from 'vuelidate'
import { validateFullDate } from '@/utils/dateTimeHelper'
import { isEndDateBiggerThanStartDate } from '@/utils/validationHelpers'
import { dateRangeHasErrorMessage, isInvalidErrorMessage } from '@/../../shared/constants/validationMessageHelper.js'

import TitleText from '@/components/atoms/TitleText'
const PriceAgreementList = () => import('@/components/organisms/Result/PriceAgreementList')

import filterMixin from '@/../../shared/mixins/filterMixin'

export default {
    name: 'PriceAgreementsOverview',
    components: {
        TitleText,
        PriceAgreementList
    },
    mixins: [validationMixin, filterMixin],
    validations: {
        selectedFilters: {
            minStartDate: {
                minValue(value, selectedFilters) {
                    return isEndDateBiggerThanStartDate(
                        selectedFilters.minStartDate,
                        selectedFilters.maxStartDate
                    )
                },
                validDate(value) {
                    return validateFullDate(value)
                }
            },
            maxStartDate: {
                minValue(value, selectedFilters) {
                    return isEndDateBiggerThanStartDate(
                        selectedFilters.minStartDate,
                        selectedFilters.maxStartDate
                    )
                },
                validDate(value) {
                    return validateFullDate(value)
                }
            },
            minEndDate: {
                minValue(value, selectedFilters) {
                    return isEndDateBiggerThanStartDate(
                        selectedFilters.minEndDate,
                        selectedFilters.maxEndDate
                    )
                },
                validDate(value) {
                    return validateFullDate(value)
                }
            },
            maxEndDate: {
                minValue(value, selectedFilters) {
                    return isEndDateBiggerThanStartDate(
                        selectedFilters.minEndDate,
                        selectedFilters.maxEndDate
                    )
                },
                validDate(value) {
                    return validateFullDate(value)
                }
            }
        }
    },
    data() {
        return {
            BUTTON_TEXT,
            PROVIDER,
            selectedFilters: {
                searchQuery: '',
                minStartDate: null,
                maxStartDate: null,
                minEndDate: null,
                maxEndDate: null
            } 
        }
    },
    computed: {
        minStartDateError() {
            return (
                isInvalidErrorMessage(PROVIDER.ResultsAndContractsPage.ContractPriceAgreementsPage.FilterInput.MinStartDate.Label, this.$v.selectedFilters.minStartDate, 'validDate') ||
                dateRangeHasErrorMessage(
                    'minDate',
                    this.$v.selectedFilters.minStartDate,
                    'minValue'
                )
            ) 
        },
        maxStartDateError() {
            return (
                isInvalidErrorMessage(PROVIDER.ResultsAndContractsPage.ContractPriceAgreementsPage.FilterInput.MaxStartDate.Label, this.$v.selectedFilters.maxStartDate, 'validDate') ||
                dateRangeHasErrorMessage(
                    'maxDate',
                    this.$v.selectedFilters.maxStartDate,
                    'minValue'
                )
            ) 
        },
        minEndDateError() {
            return (
                isInvalidErrorMessage(PROVIDER.ResultsAndContractsPage.ContractPriceAgreementsPage.FilterInput.MinEndDate.Label, this.$v.selectedFilters.minEndDate, 'validDate') ||
                dateRangeHasErrorMessage(
                    'minDate',
                    this.$v.selectedFilters.minEndDate,
                    'minValue'
                )
            )
        },
        maxEndDateError() {
            return (
                isInvalidErrorMessage(PROVIDER.ResultsAndContractsPage.ContractPriceAgreementsPage.FilterInput.MaxEndDate.Label, this.$v.selectedFilters.maxEndDate, 'validDate') ||
                dateRangeHasErrorMessage(
                    'maxDate',
                    this.$v.selectedFilters.maxEndDate,
                    'minValue'
                )
            )
        }
    },
    methods: {  
        setMinStartDate(newValue) {
            if(this.minStartDateError) return

            this.setSpecificFilter('minStartDate', newValue)
        },
        setMaxStartDate(newValue) {
            if(this.maxStartDateError) return

            this.setSpecificFilter('maxStartDate', newValue)
        },
        setMinEndDate(newValue) {
            if(this.minEndDateError) return

            this.setSpecificFilter('minEndDate', newValue)
        },
        setMaxEndDate(newValue) {
            if(this.maxEndDateError) return

            this.setSpecificFilter('maxEndDate', newValue)
        },
        resetFilters() { 
            this.selectedFilters = {
                searchQuery: '',
                minStartDate: null,
                maxStartDate: null,
                minEndDate: null,
                maxEndDate: null
            }
            
            this.localFilters = JSON.parse(JSON.stringify(this.selectedFilters))
        }
    }
}
</script>
